<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ macrossegmentoEconomico.id ? 'Atualizar Macrossegmento Econômico' : 'Novo Macrossegmento Econômico' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="macrossegmentoEconomico.title" />
                </div>
                <div class="field col-9">
                    <label class="required">Macrossegmento Sas</label>
                    <Dropdown v-model="macrossegmentoEconomico.codigoSas" :options="macrossegmentoSas" optionValue="ID" optionLabel="Nome" showClear />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="macrossegmentoEconomico.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button
                label="Salvar"
                @click.stop="inserir"
                icon="pi pi-check-circle"
                :disabled="!macrossegmentoEconomico.title || !macrossegmentoEconomico.codigoSas"
            ></Button>
        </div>
    </div>
</template>
<script>
import Services from './service';

export default {
    data() {
        return {
            macrossegmentoEconomico: {
                active: true,
                title: null,
                codigoSas: null,
                descricaoSas: null,
            },
            macrossegmentoSas: [],
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.macrossegmentoEconomico = response.data;
                }
            });
        }
        this.obterMacrossegmentoSas();
    },

    methods: {
        inserir() {
            this.$store.dispatch('addRequest');

            if (this.macrossegmentoEconomico.id) {
                Services.atualizar(this.macrossegmentoEconomico.id, this.macrossegmentoEconomico).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                Services.inserir(this.macrossegmentoEconomico).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Macrossegmento Econômico',
                    detail: `Macrossegmento Econômico ${edicao ? 'editado' : 'inserido'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        obterMacrossegmentoSas() {
            this.$store.dispatch('addRequest');
            Services.obterMacrossegmentoSas().then((response) => {
                if (response?.success) {
                    this.macrossegmentoSas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                    let macrossegmentoDto = {
                        ID: this.macrossegmentoEconomico.codigoSas,
                        Nome: this.macrossegmentoEconomico.descricaoSas,
                    };
                    this.macrossegmentoSas.push(macrossegmentoDto);
                }
                this.$store.dispatch('removeRequest');
            });
        },
        cancelar() {
            this.$router.push({
                name: `MacrossegmentoEconomico`,
            });
        },
    },
    watch: {
        'macrossegmentoEconomico.codigoSas'() {
            if (this.macrossegmentoEconomico.codigoSas > 0 && this.macrossegmentoSas.length > 0) {
                const result = this.macrossegmentoSas.filter((macrossegmento) => macrossegmento.ID == this.macrossegmentoEconomico.codigoSas);
                this.macrossegmentoEconomico.descricaoSas = result[0].Nome;
                this.macrossegmentoEconomico.codigoSas = result[0].ID;
            }
        },
    },
};
</script>
